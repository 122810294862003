import { graphql, Link } from "gatsby";
import React from "react";
import Head from "../../components/head";
import Layout from "../../components/layout";
import BlocsContent from "../../components/sections/BlocsContent";
import ContactLink from "../../components/sections/ContactLink";
import HeroStandard from "../../components/sections/HeroStandard";
import FeaturedParcours from "../../components/sections/FeaturedParcours";
import Share from "../../components/sections/Share";

export const query = graphql`
  query ($slug: String!) {
    post: contentfulPost(slug: { eq: $slug }) {
      title

      blocs {
        ... on Node {
          ... on ContentfulBlocArticle {
            __typename
            id
            title
            textContent {
              raw
            }
            image {
              title
              gatsbyImageData(
                layout: FULL_WIDTH
                width: 600
                placeholder: DOMINANT_COLOR
              )
            }
            isImageLeft
            link
            buttonLabel
            bgColor
          }

          ... on ContentfulBlocVideo {
            __typename
            id
            title
            video_url
            bgColor
          }

          ... on ContentfulBlocGalerieDePhotos {
            __typename
            id
            title
            text {
              raw
            }
            bgColor
            images {
              title
              gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }

    featuredParcours: allContentfulParcours(
      limit: 3
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        title
        slug
        accroche
        excerpt {
          excerpt
        }
        difficulty
        time
        image {
          gatsbyImageData(
            layout: FIXED
            placeholder: BLURRED
            width: 370
            height: 220
          )
        }
      }
    }
  }
`;

const Post = ({ data, location }) => {
  const { title, blocs } = data.post;
  const parcoursTitle =
    "Découvrez la ville de Strasbourg en courant avec un guide coach";
  const featuredParcours = data.featuredParcours.nodes;
  const parcoursButton = "Voir tous nos parcours";

  return (
    <Layout>
      <Head
        title={title}
        description={title + " - article de Strasbourg Running Tours"}
      />
      <HeroStandard title={title} />
      <div className="breadcrumbs">
        <Link to="/">Accueil</Link> > <Link to="/actualites">Actualités</Link> >{" "}
        <span>{title}</span>
      </div>
      {blocs && <BlocsContent blocs={blocs} />}
      <div className="full-width">
        <div className="grid-container">
          <Share title={title} url={location.href} />
        </div>
      </div>
      <FeaturedParcours
        parcoursTitle={parcoursTitle}
        featuredParcours={featuredParcours}
        parcoursButton={parcoursButton}
        addClass="bg-light"
      />
      <ContactLink />
    </Layout>
  );
};

export default Post;
